@import url("https://fonts.googleapis.com/css2?family=Akaya+Telivigala&family=Oi&family=Oswald:wght@300&family=Reggae+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Agbalumo&family=Alata&family=Barlow+Semi+Condensed:wght@100&family=Bricolage+Grotesque&family=Dancing+Script:wght@500&family=Dongle:wght@300&family=Edu+TAS+Beginner&family=El+Messiri&family=Gluten:wght@200&family=Great+Vibes&family=Inconsolata:wght@200&family=Libre+Baskerville&family=Mynerve&family=Noto+Sans+JP:wght@800&family=Noto+Sans:wght@700&family=Playfair+Display&family=Quintessential&family=Sedgwick+Ave+Display&family=Tulpen+One&family=Ysabeau+Infant:wght@1&display=swap");
html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
}
#root {
  width: 100%;
  height: 100%;
}
.App {
  display: flex;
  background-color: #183a37;
  height: 100%;
  box-sizing: border-box;
}
.App2 {
  background-color: #333;
  display: flex;
  height: 100%;
  box-sizing: border-box;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.li_Div {
  display: flex;
  flex-direction: row;
  padding-top: 20px 0px;
  width: 100%;
}
.ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 100%;
}
.mo {
  display: flex;
  font-family: "Reggae One", cursive;
  font-size: 3rem;
  color: white;
  margin-left: 40px;
}
.icon {
  height: 24px;
  font-size: 30px;
  cursor: pointer;
}
.clickme {
  position: relative;
  color: aliceblue;
  font-size: 0.8rem;
  margin-top: -10px;
}
.welcomeText-div {
  text-align: center;
}
.welcomeText {
  font-size: 2rem;
  font-family: "Reggae One", cursive;
  color: white;
}

.div_2 {
  display: flex;
  flex-direction: row;
}
.name_Div {
  display: flex;
  flex-direction: column;
}

.myimage {
  height: 100%;
  width: 100%;
  border: 5px solid transparent;
  border-radius: 50%;
}
.imageContainer {
  margin-left: 40px;
  height: 450px;
  width: 450px;
  margin-top: 10px;
}
.name {
  display: flex;
  width: 100%;
  font-family: "Oswald", sans-serif;
  font-size: 2rem;
  color: whitesmoke;
  margin-left: 40px;
}
.href_Div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
}
.sub_href {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-right: 40px;
  font-size: 2rem;
  font-family: "Oswald", sans-serif;
}

.a {
  color: white;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
}
.sub_href a {
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 400px) {
  html {
    width: 100%;
    height: 100%;
  }
  body {
    width: 100%;
    height: 100%;
  }
  #root {
    width: 100%;
    height: 100%;
  }
  .App {
    width: 100%;
    height: 100%;
    background-color: #183a37;
  }
  .App2 {
    background-color: #333;
    width: 100%;
    height: 100%;
  }
  .container {
    width: 100%;
    height: 100%;
  }
  .ul {
    display: flex;
    list-style: none;
    margin: 0px 0px;
    padding-top: 30px;
  }
  .mo {
    font-size: 1.2rem;
    margin-left: 10px;
    font-family: "Reggae One", cursive;
    color: white;
  }

  .welcomeText-div {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .welcomeText {
    font-size: 1.2rem;
    font-family: "Reggae One", cursive;
    color: white;
  }
  .div_2 {
    display: flex;
    flex-direction: row;
    height: 70vh;
    margin-top: 70px;
  }
  .imageContainer {
    height: 150px;
    width: 150px;
    margin-left: 20px;
  }

  .myimage {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .href_Div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
  }

  .sub_href {
    gap: 1rem;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    font-family: "Oswald", sans-serif;
  }
  .sub_href a {
    color: white;
    text-decoration: none;
  }
  .a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  .name {
    display: flex;
    font-size: 1.5rem;
    margin-left: 20px;
    margin-top: 250px;
    font-family: "Oswald", sans-serif;
    color: whitesmoke;
  }

  .clickme {
    position: relative;
    margin-left: 200px;
    color: aliceblue;
    font-size: 0.5rem;
    margin-top: -10px;
  }

  .icon {
    display: flex;
    margin-left: 200px;
    height: 14px;
    font-size: 30px;
    cursor: pointer;
  }
}

/* //The profile styling starts. */

.container1 {
  display: flex;
  flex-direction: column;
  background-color: #183a37;
}

.h1 {
  font-weight: 500;
  text-align: center;
  color: white;
  border: 1px solid orange;
  padding: 10px 0px;
  font-family: "Reggae One", cursive;
}

@media only screen and (max-width: 400px) {
  .h1 {
    margin-left: 0px;
    font-size: 1.5rem;
  }
  .paragraph-container {
    text-align: center;
    background-color: black;
    padding: 30px 30px;
    border: 20px solid white;
    border-radius: 30px 30px;
    border-style: ridge;
    font-size: 0.5rem;
    color: white;
    font-family: "El Messiri", sans-serif;
  }
  h2 {
    font-size: 1.5rem;
    color: white;
    font-family: "Oswald", sans-serif;
  }
  .video-list aside {
    border: 2px solid black;
    border-radius: 15px 15px;
    padding: 10px 10px;
    font-size: 1rem;
    font-family: "Oswald", sans-serif;
  }
  video {
    height: 200px;
    width: 200px;
  }
  .video-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    gap: 20px;
    list-style: none;
    border-top: 2px solid black;
    padding: 20px 0px;
    width: 300px;
    margin: 0px auto;
  }
}

.paragraph-container {
  height: 700px;
  width: 620px;
  text-align: center;
  background-color: black;
  padding: 30px 30px;
  border: 20px solid white;
  border-radius: 30px 30px;
  border-style: ridge;
  font-size: 1.1rem;
  color: white;
  font-family: "El Messiri", sans-serif;
}
.div1 {
  padding: 30px 30px;
  background-image: url("./Asset/image1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
h2 {
  font-size: 2.5rem;
  color: white;
  font-family: "Oswald", sans-serif;
}
.video-section {
  text-align: center;
  background-color: #183a37;
}
.video-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  list-style: none;
  border-top: 2px solid black;
  padding: 20px 0px;
}
.video-list aside {
  border: 2px solid black;
  border-radius: 15px 15px;
  padding: 10px 10px;
  font-size: 2rem;
  font-family: "Oswald", sans-serif;
}
.video-list aside:hover {
  color: white;
}
.homeBtn {
  padding: 10px 0px;
  color: white;
  background-color: orange;
  cursor: pointer;
}
